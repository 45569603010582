<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('jobfield.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id">
        {{ $t('jobfield.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :label="$t('jobfield.name')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-text-field
            v-model="code"
            :label="$t('jobfield.code')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-select
            v-bind:items="tpes"
            v-model="tpe"
            :label="$t('jobfield.tpe')"
            bottom
            item-text="name"
            item-value="id"
          ></v-select>

          <v-text-field
            v-show="tpe == 5"
            v-model="list"
            :label="$t('jobfield.list')"
            persistent-hint
            hint="Разделитель ','. Например Да,Нет"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" text @click.native="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const messageBox = create(confirm)

export default {
  props: ['title', 'view'],
  data() {
    return {
      tpe: '',
      name: '',
      code: '',
      list: '',
      id: '',

      dialog: true,
      valid: false,
      btnDel: false,
      tpes: [],
    }
  },
  computed: {},
  mounted: function () {
    var t = this
    this.btnDel = this.view.id ? true : false
    if (this.view.id) {
      this.$http
        .post(this.$store.state.apiUrl + 'jobfield/index/' + this.view.id)
        .then(
          (response) => {
            t.name = response.body.name
            t.code = response.body.code
            t.tpe = response.body.tpe
            t.list = response.body.list
            this.loadTpe()
          },
          (err) => {
            console.log(err)
          }
        )
    } else {
      this.loadTpe()
    }
  },
  methods: {
    loadTpe: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'jobfield/tpe/').then(
        (response) => {
          t.tpes = response.body
          if (!t.tpe) {
            t.tpe = t.tpes[0].id
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    del: function () {
      var t = this
      messageBox({
        text: 'Вы хотите удалить доп. поле заказа?',
        type: 'delete',
        title: 'Подтверждение удаления',
      })
        .transition()
        .then((response) => {
          if (response) {
            this.$http
              .post(this.$store.state.apiUrl + 'jobfield/delete/', {
                id: t.view.id,
              })
              .then(
                (response) => {
                  t.$store.dispatch('setMessage', {
                    type: response.body.err == 1 ? 'error' : 'success',
                    message: response.body.msg,
                  })
                  if (!response.body.err) {
                    t.$emit('close', 'reload')
                  }
                },
                (err) => {
                  console.log(err)
                }
              )
          }
        })
    },
    save: function () {
      var t = this
      // var arr = Object.assign({}, this.view)
      if (this.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'jobfield/save/', {
            name: t.name,
            code: t.code,
            tpe: t.tpe,
            list: t.list,
            id: t.view.id,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
``
